<template>
  <div class="container">
    <div class="top">
      <div class="main-title">理念<font> / IDEA</font>
      </div>
    </div>
    <div class="body">
      <ul>
        <li>
          <img class="icon" src="/static/img/about/idea/icon_1.png">
          <div class="title">企业理念</div>
          <p>
            传播汉语<br>
            赋能未来
          </p>
        </li>
        <li>
          <img class="icon" src="/static/img/about/idea/icon_2.png">
          <div class="title">教学理念</div>
          <p>
            演播一体<br>
            声趣课堂
          </p>
        </li>
        <li>
          <img class="icon" src="/static/img/about/idea/icon_3.png">
          <div class="title">广告词语</div>
          <p>
            让说话更艺术<br>
            让表演更生动
          </p>
        </li>
        <li>
          <img class="icon" src="/static/img/about/idea/icon_4.png">
          <div class="title">课堂定位</div>
          <p>
            主持表演<br>
            简单有趣
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {},

  methods: {
    newHoverHanlder(idx) {
      this.picActived = false;

      this.itemIndex = idx;
      setTimeout(() => {
        this.picActived = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 707px;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 110px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }

  .body {
    width: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;

    ul {
      list-style: none;
      display: flex;

      li {
        display: flex;
        flex-direction: column;
        width: 130px;
        align-items: center;
        margin: 0 72.5px;

        .icon {
          width: 130px;
          height: 130px;
        }

        .title {
          margin-top: 30px;
          font-size: $font-size-26;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: $font-color-orange;
        }

        p {
          margin: 0;
          margin-top: 10px;
          color: $font-color-black;
          font-size: $font-size-16;
          font-weight: 400;
        }
      }
    }
  }
}
</style>