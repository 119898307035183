<template>
  <div class="container">
    <div class="top">
      <div class="main-title">吉祥物（表情包）<font> / MASCOT</font>
      </div>
    </div>
    <div class="body">
      <div class="content">
        <div class="imgs">
          <div class="img" v-for="(img, key) in imgs" :key="key">
            <img :src="img.src">
          </div>
        </div>
      </div>
    </div>
    <div class="qrcodes">
      <div class="qrcodes-title">扫码下载筒筒微信表情包</div>
      <div class="imgs">
        <div class="img" v-for="(img, key) in imgs" :key="key">
          <img :src="img.qrcode">
          <div class="label">
            <span>{{img.name}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // qrcode: "",
      float: false,
      imgs: [
        {
          name: "第一组",
          src: "/static/img/about/mascot/icon_1.gif",
          qrcode: "",
          qrcode_show: false,
        },
        {
          name: "第二组",
          src: "/static/img/about/mascot/icon_2.gif",
          qrcode: "",
          qrcode_show: false,
        },
        {
          name: "第三组",
          src: "/static/img/about/mascot/icon_3.gif",
          qrcode: "",
          qrcode_show: false,
        },
      ],
      closeHover: false,
    };
  },
  props: {
    args: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    args(args) {
      this.qrcode = args.qrcode;
      this.imgs[0].qrcode = args.wechat_emoji_qrcode_1;
      this.imgs[1].qrcode = args.wechat_emoji_qrcode_2;
      this.imgs[2].qrcode = args.wechat_emoji_qrcode_3;
    },
  },
  methods: {
    showQrcode(idx) {
      this.imgs[idx].qrcode_show = true;
    },
    hideQrcode(idx) {
      this.imgs[idx].qrcode_show = false;
    },
    closeMourseOverHandler() {
      this.closeHover = true;
    },
    closeMourseLeaveHandler() {
      this.closeHover = false;
    },
    newHoverHanlder(idx) {
      this.picActived = false;

      this.itemIndex = idx;
      setTimeout(() => {
        this.picActived = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1150px;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }

  .body {
    width: 100%;
    margin-top: 50px;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      width: 1180px;
      height: 420px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .imgs {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;

        .img {
          width: 333px;
          display: flex;
          padding: 0 10px;
          position: relative;
          align-items: center;
          justify-content: center;

          img {
            width: 250px;
            height: 250px;
          }
        }
      }
    }
  }

  .qrcodes {
    margin-top: 10px;
    width: 1180px;
    height: 420px;
    background: #fff5f0;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .qrcodes-title {
      color: white;
      border: 4px solid white;
      background: #ffa83a;
      font-size: 22px;
      font-weight: bold;
      padding: 15px 49px;
      border-radius: 45px;

      position: absolute;
      top: -30px;
    }

    .imgs {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 70px;
      padding-bottom: 95px;

      .img {
        width: 255px;
        height: 255px;
        display: flex;
        margin: 0 50px;
        position: relative;
        background: white;
        align-items: center;
        justify-content: center;
        border-radius: 30px;

        img {
          width: 180px;
          height: 180px;
        }

        .label {
          position: absolute;
          bottom: -25px;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: $font-color-white;
            width: 123px;
            height: 48px;
            background: #ff6f1b;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>