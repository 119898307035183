<template>

  <div class="container">

    <!-- <div v-if="false"> -->
    <Carousel :args="args.slide" />
    <About :args="args.about" />
    <Mession />
    <Idea />
    <ThemeSong :args="args.theme_song" />
    <!-- </div> -->
    <Mascot :args="args.mascot" />
  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
import About from "@/components/home/about.vue";
// import About from "@/components/about/about.vue";
import Mession from "@/components/about/mession.vue";
import Idea from "@/components/about/idea.vue";
import ThemeSong from "@/components/about/theme_song.vue";
import Mascot from "@/components/about/mascot.vue";
export default {
  components: {
    Carousel,
    About,
    Mession,
    Idea,
    ThemeSong,
    Mascot,
  },
  data() {
    return {
      args: {},
    };
  },
  created() {
    this.$api("/official/page/about").then(({ data }) => {
      this.args = data;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
}
</style>

