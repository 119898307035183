<template>
  <div class="news-container">
    <div class="top">
      <div class="main-title">我们<font> / ABOUT US</font>
      </div>
      <div class="sub-title">传播汉语 赋能未来</div>
    </div>
    <div class="body">
      <div class="cover">
        <div class="mask" @click="showFullScreenVideo(video)">
          <img class="open" src="/static/img/mobile/open.png" />
        </div>
        <img :src="cover" class="img" />
      </div>

      <div class="info">
        <div class="info-title">北京筒筒教育科技有限公司</div>
        <p class="info-content">
          {{text}}
        </p>
        <!-- <div class="button" @click="skipAbout">查看更多</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cover: "",
      video: "",
      text: "",
    };
  },
  props: {
    args: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    args(args) {
      this.cover = args.cover;
      this.video = args.video;
      this.text = args.text;
    },
  },
  created() {
    console.log(this.$fullVideoObj);
  },
  methods: {
    skipAbout() {
      this.$router.push({ path: "/about" });
    },
    newHoverHanlder(idx) {
      this.picActived = false;

      this.itemIndex = idx;
      setTimeout(() => {
        this.picActived = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.news-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      margin-top: 10px;
      font-size: $font-size-16;
      color: $font-color-gray;
    }
  }

  .body {
    margin-top: 50px;
    padding-bottom: 86px;
    display: flex;

    .cover {
      width: 815px;
      height: 515px;
      box-shadow: 0px 2px 25px 0px rgba(234, 77, 0, 0.2);
      border-radius: 26px;
      overflow: hidden;
      position: relative;

      .img {
        width: 100%;
        height: 100%;
      }

      .mask {
        cursor: pointer;

        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        background: rgba($color: #000000, $alpha: 0.2);

        // width: 815px;
        // height: 515px;
        box-shadow: 0px 4px 42px 0px rgba(234, 77, 0, 0.2);
        border-radius: 26px;
        // margin-left: 47px;

        display: flex;
        align-items: center;
        justify-content: center;
        .open {
          -webkit-user-drag: none;
          width: 50px;
          height: 50px;
        }
      }
    }
    .info {
      // margin-top: 88px;
      margin-left: 40px;
      min-width: 264px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > .info-title {
        font-size: $font-size-22;
        color: $font-color-orange;
        font-weight: bold;
      }

      > .info-content {
        width: 264px;
        margin-top: 30px;
        text-align: justify;
        color: $font-color-black;
        // font-size: $font-size-12;
        font-size: 16px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-indent: 2em;
        // height: 327px;
      }

      > .button {
        margin-top: auto;
        width: 240px;
        height: 54px;
        background: linear-gradient(180deg, #ffa83a 0%, #ff6f1b 100%);
        border-radius: 27px;
        font-size: 24px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Regular, PingFang SC;
        border: 1px solid white;
        cursor: pointer;

        &:hover {
          border: 1px solid $border-color-orange;
          color: #ff8f3c;
          background: none;
          transition: 1s;
          background-color: white;
        }
      }
    }
  }
}
</style>