<template>
  <div class="container">
    <div class="top">
      <div class="main-title">主题歌<font> / THEME SONG</font>
      </div>
    </div>
    <div class="body">
      <img class="people" src="/static/img/about/theme_song/people.png?v=1">
    </div>
    <div class="theme_song_block">
      <div class="theme_song">
        <div class="disc-mask" v-show="discMourseOver" @mouseleave="discMourseLeaveHandler">
          <img @click="stopAudio" v-show="rotated" class="disc-open" src="/static/img/about/theme_song/stop.png">
          <img @click="openAudio" v-show="!rotated" class="disc-close" src="/static/img/about/theme_song/open.png">
        </div>
        <img @mouseenter="discMourseOverHandler" :class="{disc:true, rotated:rotated}"
          src="/static/img/about/theme_song/run.png" />
        <ul class="lrcs">
          <li class="lrc">{{preLrc}}</li>
          <li class="lrc actived">{{currLrc}}</li>
          <li class="lrc">{{nextLrc}}</li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      audio: null,
      //全部歌词
      lrcAll: {},
      //展示歌词
      preLrc: "",
      currLrc: "",
      nextLrc: "",
      rotated: true,
      discMourseOver: false,
      init: false,
    };
  },
  props: {
    args: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    args(args) {
      if (!args) {
        return;
      }

      if (this.init) {
        return;
      }
      this.init = false;

      this.initMp3(args);
    },
  },
  created() {
    // this.$api("/official/index/aboutThemeSong").then(({ data }) => {
    //   console.log(this.lrcAll);
    // });
    this.$root.eventHub.$on("MediaStop", () => {
      this.audio && this.audio.play();
    });
    this.$root.eventHub.$on("MediaPlay", () => {
      this.audio && this.audio.pause();
    });
  },
  methods: {
    initMp3(data) {
      this.parseLrc(data.lrc).ms.forEach((lrc) => {
        this.lrcAll[parseInt(lrc.t)] = lrc.c;
      });

      //初始化展示的歌词
      const keys = Object.keys(this.lrcAll);
      this.preLrc = "";
      this.currLrc = this.lrcAll[keys[0]];
      this.nextLrc = this.lrcAll[keys[1]];

      this.audio = this.loadAudio({
        src: data.mp3,
      });

      let interval = setInterval(() => {
        //当前时间
        let key = this.audio.currentTime.toFixed(0);
        const keys = Object.keys(this.lrcAll);

        //通过时间秒数找到匹配的歌词并找到前后得歌词
        for (let k = 0; k < keys.length; k++) {
          if (keys[k] == key) {
            this.preLrc = this.lrcAll[keys[k - 1]] || "";
            this.currLrc = this.lrcAll[keys[k]] || "";
            this.nextLrc = this.lrcAll[keys[k + 1]] || "";
            // console.log(
            //   JSON.stringify({
            //     pre: this.lrcAll[keys[k - 1]],
            //     curr: this.lrcAll[keys[k]],
            //     next: this.lrcAll[keys[k + 1]],
            //   })
            // );
            break;
          }
        }
      }, 1000);

      let interval2 = setInterval(() => {
        //判断是否还是改页面如果不是则直接停止
        if (this.$route.path.split("/").pop() != "about") {
          this.audio.pause();
          this.audio = null;
          clearInterval(interval);
          clearInterval(interval2);
          return;
        }
      }, 250);
    },
    discMourseLeaveHandler() {
      setTimeout(() => {
        this.discMourseOver = false;
      }, 100);
    },
    discMourseOverHandler() {
      this.discMourseOver = true;
    },
    stopAudio() {
      this.audio.pause();
      this.rotated = false;
    },
    openAudio() {
      this.audio.play();
      this.rotated = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
  height: 960px;
  background: rgba($color: #ff6f1b, $alpha: 0.06);
  position: relative;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 999;

    .main-title {
      padding-top: 50px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }

  .body {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;

    .people {
      width: 900px;
      height: 925px;
    }
  }

  .theme_song_block {
    position: absolute;
    bottom: 53px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    .theme_song {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 400px;
      height: 122px;

      background: #fff;
      border: 1px solid $border-color-orange;
      border-radius: 100px;
      position: relative;

      .disc-mask {
        background-color: rgba($color: #000000, $alpha: 0.3);
        width: 154px;
        height: 153px;
        position: absolute;
        top: -20px;
        left: -49px;
        border-radius: 100%;
        z-index: 9999999;

        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        .disc-open,
        .disc-close {
          transition: 1s;
          cursor: pointer;
          user-select: none;
          width: 60px;
          height: 60px;
        }
      }

      .disc {
        width: 154px;
        height: 153px;
        position: absolute;
        z-index: 99999;
        top: -20px;
        left: -49px;

        &.rotated {
          @-webkit-keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
          transform: rotate (360deg);
          animation: rotation 10s linear infinite;
        }
      }

      .lrcs {
        padding-left: 50px;
        position: absolute;
        z-index: 999;
        list-style: none;
        font-size: 16px;
        color: $font-color-black;
        line-height: 27px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transition: 1s;

        .lrc {
          padding: 0 22px 0 7px;

          &.actived {
            color: $font-color-orange;
            border-bottom: 1px solid $border-color-orange;
            position: relative;

            &::after {
              position: absolute;
              z-index: 99999;
              content: " ";
              width: 28px;
              height: 28px;
              right: -20px;
              // background: black;
              background-size: 100%;
              background-image: url("/static/img/about/theme_song/icon_lrc.png");
            }
          }
        }
      }
    }
  }
}
</style>