<template>
  <div class="news-container">
    <div class="top">
      <div class="main-title">使命<font> / MISSION</font>
      </div>
    </div>
    <div class="body">
      <div class="people">
        <img class="img" src="/static/img/about/mession/people.png?v=1" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {},

  methods: {
    newHoverHanlder(idx) {
      this.picActived = false;

      this.itemIndex = idx;
      setTimeout(() => {
        this.picActived = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.news-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 110px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }

  .body {
    width: 100%;
    margin-top: 158px;
    height: 675px;
    background: rgba($color: #ff6f1b, $alpha: 0.07);

    .people {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;

      display: flex;
      justify-content: center;

      overflow: hidden;
      .img {
        width: 1070px;
        height: 802px;
      }
    }
  }
}
</style>